import { EventEmitter, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  /********************************************passing QR Code value from login to inner module********************************************************/
  private subject = new BehaviorSubject<any>(null);
  public QrObservable: Observable<any> = this.subject.asObservable();
  /****************************************************************************************************************************************************/

  constructor(
    private _location: Location,
    private router: Router,
  ) {

    /*******************since observable clears values on page refresh, use this as it runs everytime service is called******************************/
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const token = this.getLocalData('qrToken');
        if (token) {
          this.sendMessage = token;
        } else {
          this.clearAllLocalData();
          router.navigate(['/auth/login'])
        }
      }
    });
    /****************************************************************************************************************************************************/
  }

  /********************************************passing QR Code value from login to inner module********************************************************/
  set sendMessage(uniqueCode: string) {
    this.subject.next({ text: uniqueCode });
  }

  get getMessage() {
    return this.QrObservable;
  }
    /****************************************************************************************************************************************************/


  getToken() {
    if (!!localStorage.getItem('reward_cashier')) {
      return localStorage.getItem('reward_cashier')
    } else {
      return '';
    }
  }

  setToken(data) {
    localStorage.setItem('reward_cashier', data);
  }

  setLocalData(key: string, data: any, json?: boolean) {
    localStorage.setItem(key, json ? JSON.stringify(data) : data);
  }

  getLocalData(key: string, json?: boolean) {
    let _data: any = null;
    try {
      _data = json
        ? JSON.parse(localStorage.getItem(key))
        : localStorage.getItem(key);
      return _data;
    } catch (error) {
      if (error instanceof SyntaxError) this.clearLocalData(key);
      return null;
    }
  }

  clearAllLocalData() {
    localStorage.clear();
  }

  clearLocalData(key: string) {
    localStorage.removeItem(key);
  }
  // localstorage setvalue, clear and get value end

  goBack() {
    this._location.back();
  }

  cal(page, limit, count) {
    if (page * limit <= count) {
      return page * limit;
    } else {
      return count;
    }
  }

}



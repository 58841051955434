import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad} from '@angular/router';
import { UiService } from '../ui.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutGuardService implements CanLoad{

  constructor(
    private router: Router,
    // private message: MessageService,
    private uiService: UiService
  ) { }

  canLoad(): boolean {
    if (!!this.uiService.getToken()) {
      return true;
    }
    localStorage.clear();
    this.router.navigate(['/auth/login'])
    // this.message.alert('info', SuccessErrorConst.loginAgin);
    return false;
  }
}

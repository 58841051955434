import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { UiService } from '../ui.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanLoad{

  constructor(protected router: Router,
    private uiService: UiService
) { }

canLoad() {
    if (!this.uiService.getToken()) {
        return true;
    }
    this.router.navigate(['/cashier/form']);
    return false;
}
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from './services/guards/auth-guard.service';
import { LayoutGuardService } from './services/guards/layout-guard.service';

const routes: Routes = [
  {
    path: '', redirectTo: '/auth/login', pathMatch: 'full'
  },
  {
    path: 'auth', loadChildren: () => import('./layout/auth/auth.module').then(m => m.AuthModule), canLoad: [AuthGuardService]
  },
  {
    path: 'cashier', loadChildren: () => import('./layout/cashier/cashier.module').then(m => m.CashierModule), canLoad: [LayoutGuardService]
  },
  {
    path: '**', redirectTo: '/auth/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

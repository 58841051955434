<section class="start-dashboard">

    <div class="container-fluid p-0">
        <div class="top-bar">
            <div class="row align-items-center">
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <ul class="d-flex align-items-center">
                        <li><a><img src="assets/images/ic_logo.png"></a></li>
                        <li><a><img src="assets/images/ic_drawe.png" class="pl-4"></a></li>
                    </ul>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12">
                    <ul class="navbar nav align-items-center pull-right">
                        <li class="ml-auto">
                             <a class="instruction2 ml-auto">
                                <img src="assets/images/ic_instruction.png" class="img-fluid pr-3">
                            </a>
                        </li>

                        <li>
                            <button type="button" class="btn logout-btn" (click)="logout()"><img
                                    src="assets/images/outline.png" class="pr-1"> Logout</button>
                                </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</section>
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from '../messages/message.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private message: MessageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  return next.handle(request)
  .pipe(
    tap(event => { }, error => {
      switch (error.status) {
        case 401:
          /********** Auto logout if 401 response returned from api **********/
          localStorage.clear();
          this.router.navigate(['/auth/login']);
          this.message.alert('error', 'OOPS!', 'Sorry, Please login again to continue.');
          break;
        case 0:
          /********** If server doesnt respond **********/
          this.message.alert('error', 'OOPS!', 'Please check internet connection!');
          break;
        default:
          /********** Check for other serve-side errors **********/
          this.message.alert('error', error.error.message);
          break;
      }
    }));
}
}

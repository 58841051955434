<section class="dashboard-body">
    <div class="container-fluid">
        <div class="row">
            <!-- left side  -->
            <div class="col-md-3 col-lg-2 col-sm-3 pr-0">
                <!-- <ul class="nav d-block nav-pills left-side-bar my-3" role="tablist">  -->
                <ul class="nav nav-pills d-block nav-pills left-side-bar" role="tablist">
                    <li routerLink="/cashier/form" routerLinkActive="active"><a data-toggle="pill"> <img
                                src="assets/images/ic_orders.png" class="pr-3 show"><img src="assets/images/ic_orders.png"
                                class="pr-3 hide"> Make Transaction</a></li>
                    <li class="nav-item" routerLink="/cashier/history" routerLinkActive="active"><a data-toggle="pill">
                            <img src="assets/images/ic_history.png" class="pr-3 show"><img src="assets/images/ic_history2.png"
                                class="pr-3 hide"> History</a></li>
                    <li class="nav-item" routerLink="/cashier/scanner" routerLinkActive="active"><a data-toggle="pill">
                            <img src="assets/images/ic_qr.png" class="pr-3 show"><img src="assets/images/ic_qr2.png"
                                class="pr-3 hide"> QR Code</a></li>
                    <li class="nav-item" routerLink="/cashier/efficiency" routerLinkActive="active"><a
                            data-toggle="pill"> <img src="assets/images/ic_profile.png" class="pr-3 show"><img
                                src="assets/images/ic_profile.png" class="pr-3 hide"> Profile</a></li>
                    <!-- <li class="nav-item" ><a data-toggle="pill"> <img src="assets/images/ic_order.png"
                                class="pr-3 show"><img src="assets/images/ic_bag2.png" class="pr-3 hide"> Order</a></li> -->
                </ul>
            </div>
            <div class="col-md-9 col-lg-10 col-sm-9 bg-right">
                <div class="tab-content">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</section>